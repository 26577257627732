<template>
    <div class="exempt container-fluid">
        <h2 class="border-bottom pb-2 mb-3">
            <i class="fa fa-ban"></i> Traffic Exemption
            <span class="float-right">
                <button class="btn btn-sm btn-info" data-toggle='modal' data-target='#addModal'>
                    <i class="fa fa-plus" ></i> Add Domain
                </button>
            </span>
        </h2>

        <div class="card">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-3 col-md-4">
                        <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                            <h5>Domain</h5>
                            <span class="e-input-group e-control-wrapper e-outline e-valid-input e-datetime-wrapper" style="width: 100%;">
                              <input class="e-control e-textbox e-outline e-lib e-input e-keyboard" placeholder="Search" v-model="filters.domain" />
                            </span>
                        </div>
                    </div>
<!--                    <div class="col-lg-2 col-md-2">-->
<!--                        <div class="tabs-wrap" style="padding-top: 65px; padding-bottom: 30px">-->
<!--                            <button class="e-control e-progress-btn e-lib e-btn e-spin-right" data-ripple="true" aria-label="SEARCH progress" style="color: white; background-color: rgb(56, 56, 56);" @click="filter">-->
<!--                                <span class="e-btn-content">SEARCH</span>-->
<!--                            </button>-->
<!--                        </div>-->
<!--                    </div>-->
                </div>
            </div>
        </div>

        <div class="card mt-4">
            <div class="card-body">
                <ejs-grid ref="grid" :dataSource="data" :query="query" :allowPaging="true" :allowSorting="true" :pageSettings="pageSettings">
                    <e-columns>
                        <e-column field="created_ts" headerText="Added" text-Align="Left" :format="{type: 'dateTime', skeleton: 'medium'}"></e-column>
                        <e-column field="domain" headerText="Domain" text-Align="Left" ></e-column>
                        <e-column :template="actionTemplate"></e-column>
                    </e-columns>
                </ejs-grid>
            </div>
        </div>

        <div class="modal fade" id="addModal">
            <div class="modal-dialog modal-sm">
                <div class="modal-content">

                    <!-- Modal Header -->
                    <div class="modal-header">
                        <h4 class="modal-title">Add Domain</h4>
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                    </div>

                    <!-- Modal body -->
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                                    <h5>Domain</h5>
                                    <span class="e-input-group e-control-wrapper e-outline e-valid-input e-datetime-wrapper" style="width: 100%;">
                                         <input class="e-control e-textbox e-outline e-lib e-input e-keyboard" placeholder="Search" v-model="domain" />
                                    </span>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <div class="tabs-wrap" style="padding-top: 65px; padding-bottom: 30px">
                                    <button class="e-control e-progress-btn e-lib e-btn e-spin-right" data-ripple="true" aria-label="SEARCH progress" style="color: white; background-color: rgb(56, 56, 56);" @click="add">
                                        <span class="e-btn-content">ADD Domain</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {GridPlugin, Page, Sort} from '@syncfusion/ej2-vue-grids';
import Vue from "vue";
import {DataManager, Query, WebApiAdaptor} from "@syncfusion/ej2-data";
import axios from "axios";
import swal from "sweetalert";
Vue.use(GridPlugin);

let Bus = new Vue();

export default {
    name: "Exempt",
    provide: {
        grid: [Page, Sort]
    },
    props: ["user"],
    data: function () {
        return {
            domain: null,
            filters: {
                domain: null
            },
            pageSettings: { pageSize: 50, pageSizes: [50, 100, 500] },
            data: new DataManager({
                url: `${this.$root.serverUrl}/admin/exempt`,
                adaptor: new WebApiAdaptor(),
                crossDomain: true,
                headers:[{ 'Authorization': 'Bearer ' + this.$cookies.get('accessToken') }]
            }),
            actionTemplate: function () {
                return {
                    template: Vue.component('tosTemplate', {
                            template: `<button @click="remove" class='btn btn-primary btn-sm'>
                                          <span class="fa fa-trash" title="View"></span>
                                        </button>`,
                            data: function () {
                                return {
                                    data: {}
                                }
                            },
                            methods: {
                                remove: function () {
                                    //Handle removing the item
                                    swal({
                                        title: "Remove",
                                        text: "Are you sure?",
                                        icon: "info",
                                        buttons: ["No", "Yes"],
                                    }).then(
                                        function (val) {
                                            if (!val) return;

                                            axios({
                                                url: `${this.$root.serverUrl}/admin/exempt/${this.data._id}`,
                                                responseType: "json",
                                                method: "DELETE",
                                            })
                                                .then(
                                                    function (resp) {
                                                        //Process the results
                                                        this.$root.preloader = false;
                                                        if (resp.data && !resp.data.error) {
                                                            //Saved successfully, now update the parent
                                                            swal({
                                                                title: "Remove",
                                                                text: "Your request was completed successfully",
                                                                icon: "success",
                                                            }).then(
                                                                function () {
                                                                    Bus.$emit("refresh");
                                                                }.bind(this)
                                                            );
                                                        } else {
                                                            //Handle errors
                                                            swal({
                                                                title: "Remove",
                                                                text: resp.data.error.message,
                                                                icon: "error",
                                                            });
                                                        }
                                                    }.bind(this)
                                                )
                                                .catch(
                                                    function (err) {
                                                        this.$root.preloader = false;
                                                        this.errormsg = err.message || "An unexpected error occured";
                                                        swal({ title: "Error", text: this.errormsg, icon: "error" });
                                                    }.bind(this)
                                                );
                                        }.bind(this)
                                    );
                                },
                            }
                        }
                    )
                }
            }
        };
    },
    computed: {
        query() {
            let filters = Object.assign({}, this.filters);
            return new Query().addParams('filters', JSON.stringify(filters));
        }
    },
    components: { },
    created: function () {
        Bus.$on("refresh", () => {
           this.search()
        })
    },
    mounted() {
        this.$root.preloader = false;
    },
    beforeDestroy() {},
    destroyed() {
        window.removeEventListener("keydown", this.windowListener);
    },
    methods: {
        search() {
            this.$nextTick(async () => {
                try {
                    this.data = await new DataManager({
                        url: `${this.$root.serverUrl}/admin/exempt`,
                        adaptor: new WebApiAdaptor(),
                        crossDomain: true,
                        headers:[{ 'Authorization': 'Bearer ' + this.$cookies.get('accessToken') }]
                    })
                } catch (err) {
                    swal({ title: "Oops", text: err.message, icon: "error" });
                }
                this.searching = false;
            })
        },
        filter() {
            let filters = Object.assign({}, this.filters);
            this.temp = filters;
        },
        add() {
            var request = { args: { domain: this.domain }};
            // this.$root.preloader = true;

            axios({
                url: `${this.$root.serverUrl}/admin/exempt`,
                data: request,
                responseType: "json",
                method: "POST",
            })
                .then(
                    function (resp) {
                        //Process the results
                        this.$root.preloader = false;
                        if (resp.data && !resp.data.error) {
                            //Saved successfully, now update the parent
                            window.$('#addModal').modal('hide')
                            swal({
                                title: "Success",
                                text: "Domain added successfully",
                                icon: "success",
                            }).then(
                                function () {
                                  this.search()
                                }.bind(this)
                            );
                        } else {
                            //Handle errors
                            swal({
                                title: "Error",
                                text: "An unexpected error occurred",
                                icon: "error",
                            });
                        }
                    }.bind(this)
                )
                .catch(
                    function (err) {
                        let errormsg = err.message || "An unexpected error occurred";
                        swal({ title: "Error", text: errormsg, icon: "error" });
                    }.bind(this)
                );
        }
    }
};
</script>

<style scoped>
.exempt .modal-dialog {
    display: flex;
    max-width: 40%;
    height: 300px;
}
.modal-header {
    background: #0d0d48;
}
.modal-title {
    color: #FFFFFF;
}
.close {
    color: #FFFFFF;
}
.modal-body {
    overflow: scroll;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>
